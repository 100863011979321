import React, { FC } from 'react'
import Layout from 'layout'
import {
  Grid,
  makeStyles,
  Theme,
  Container,
  Link,
  Breadcrumbs,
  Typography,
} from '@material-ui/core'
import { graphql } from 'gatsby'
import MainPosts from 'components/blog/mainPosts'
import Sidebar from 'components/blog/sidebar'
import BlogHeader from 'components/blog/header'
import HomeIcon from '@material-ui/icons/Home'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  content: {
    padding: theme.spacing(2, 0, 2),
  },
  Blog: {
    marginTop: theme.spacing(4),
  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}))

interface Props {
  data: {
    recentMarkdownRemark: {
      edges: {
        node: {
          id: string
          excerpt: string
          frontmatter: {
            date: string
            description: string
            title: string
          }
          slug: string
        }
      }[]
    }
  }
}

const IndexPage: FC<Props> = ({
  data: {
    recentMarkdownRemark: { edges: recentMarkdownEdges },
  },
}) => {
  const classes = useStyles()
  const recentHtmlPosts = recentMarkdownEdges.map(edge => ({
    title: edge.node.frontmatter.title,
    date: edge.node.frontmatter.date,
    slug: edge.node.slug,
    excerpt: edge.node.excerpt,
  }))

  const description =
    'Stay up to date with the latest information coming out from eBay. We add our opinions and explain how this will affect you and or your business'
  const title = 'Latest news and views about eBay'

  return (
    <Layout>
      <GatsbySeo
        title={title}
        description={description}
        openGraph={{ title: title, description: description }}
      />
      <div className={classes.content}>
        <Container maxWidth="lg">
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<DoubleArrowIcon fontSize="small" />}
          >
            <Link href="/" className={classes.link}>
              <HomeIcon className={classes.icon} />
              Home
            </Link>
            <Typography color="textPrimary" className={classes.link}>
              <ViewQuiltIcon className={classes.icon} />
              Blog
            </Typography>
          </Breadcrumbs>
          <div className={classes.Blog}>
            <Grid container spacing={4} justify="center">
              <BlogHeader />
            </Grid>
            <Grid container spacing={5} className={classes.mainGrid}>
              <MainPosts title="Recent Blogs" posts={recentHtmlPosts} />
              <Sidebar />
            </Grid>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    recentMarkdownRemark: allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 5
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            description
            title
          }
          slug
        }
      }
    }
  }
`
